import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store';
import GeneralLinkBlock from 'multisite/components/cmsContentBlocks/generalLink.vue';
import './splitMultimediaBlock.scss';

const splitMultimediaBlockContainers = document.querySelectorAll(
	"[data-feature='splitMultimediaBlock']"
);

splitMultimediaBlockContainers.forEach((splitMultimediaBlockContainer) => {
	const elements: NodeList = splitMultimediaBlockContainer.querySelectorAll(
		'[data-split-multimedia-content-block]'
	);
	if (elements.length) {
		elements.forEach((element) => {
			const htmlElement: HTMLElement = element as HTMLElement;

			const app = createApp({
				name: htmlElement?.dataset?.blockName,
			});

			app.component(
				'video-block',
				defineAsyncComponent(
					() =>
						import('multisite/components/cmsContentBlocks/components/video.vue')
				)
			);
			app.component(
				'video-item-loop',
				defineAsyncComponent(
					() => import('shared/components/VideoElements/videoItemLoop.vue')
				)
			);
			app.component('general-link-block', GeneralLinkBlock);

			app.use(store);
			app.mount(htmlElement);
		});
	}
});

